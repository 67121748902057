.firebaseui-idp-button {
    padding: 12px 22px !important;
    border-radius: 8px !important;
    max-width: 530px !important;
    width: 100% !important;
    text-align: center !important;
}

.mdl-button--raised {
    box-shadow: none !important;
}

.firebaseui-card-content {
    padding: 0 !important;
}

.firebaseui-container {
    text-align: center !important;
}
.firebaseui-idp-icon-wrapper,
.firebaseui-idp-text.firebaseui-idp-text-long{
    display: inline !important;
}
.firebaseui-idp-list{
    margin: 0 !important;
}

[data-provider-id="linkedin.com"] .firebaseui-idp-text {
    color: #FFFFFF;
    padding-left: 11px;
    font-size: 0.938rem;
    line-height: 1.125em;
    font-weight: 500;
    letter-spacing: 0.029em;
}

[data-provider-id="google.com"] .firebaseui-idp-text {
    color: #FFFFFF !important;
    padding-left: 11px;
    font-size: 0.938rem;
    line-height: 1.125em;
    font-weight: 500;
    letter-spacing: 0.029em;
}

[data-provider-id="google.com"].firebaseui-idp-button {
    background-color: #DB4437 !important;
}

[data-provider-id="linkedin.com"].firebaseui-idp-button {
    background-color: #0073B1 !important;
}

[data-provider-id="linkedin.com"].firebaseui-idp-button:hover {
    opacity: 0.8 !important;
    }

[data-provider-id="google.com"].firebaseui-idp-button:hover {
    opacity: 0.8 !important;
    }

 .firebaseui-idp-button:active {
    opacity: 0.8 !important;
    }

 .firebaseui-subtitle, .firebaseui-title {
     font-weight: 400 !important;
     font-size: 1.25rem !important;
     letter-spacing: 0 !important;
     line-height: 1.33 !important;
     padding: 0 24px !important;
 }

 .firebaseui-title {
     padding: 0 !important;
 }

 .firebaseui-text {
     font-weight: 400 !important;
     font-size: 0.875rem !important;
     letter-spacing: 0.011em !important;
     line-height: 1.43 !important;
     padding: 0 24px !important;
 }

 .google-login-button {
     background: #DB4437 !important;
     color: #FFFFFF !important;
     &:hover {
         background: #DB4437 !important;
         opacity: 0.8 !important;
     }
     padding: 10px 22px !important;
     border-radius: 8px !important;
     max-width: 530px !important;
     width: 100% !important;
     text-align: center !important;
     font-size: 0.938rem !important;
     line-height: 1.125em !important;
     font-weight: 500 !important;
     letter-spacing: 0.029em !important;
 }

 .google-logo {
      width: 20px;
      height: 20px;
      margin-right: 10px;
 }
