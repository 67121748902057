* {
    box-sizing: border-box;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.rcs-outer-container {
    height: 100% !important;
    position: relative !important;
}
.rcs-custom-scroll .rcs-outer-container .rcs-positioning{
     position: absolute !important;
}
.rcs-inner-container{
    padding-right: 20px;
}
.rcs-positioning {
    height: 100%;
    width: 3px;
    right: 0;
}

.rcs-custom-scrollbar {
    bottom: 6px !important;
    top: 6px !important;
    height: auto !important;
    padding: 0 !important;
    background: #FAFAFA !important;
    border-radius: 10px !important;
    width: 4px !important;
}

.rcs-inner-handle {
    background-color: #E6E6E6 !important;
    border-radius: 10px !important;
    width: 4px !important;
    margin-top: 0 !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
